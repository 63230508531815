// @flow
/* global window */
import * as React from 'react';
import HtmlNode from '../HtmlNode/HtmlNode';
// import Paragraph from '../Paragraph/Paragraph';

type Props = {
  htmlContent: Array<Object>,
  mobileHtmlContent?: ?Array<Object>,
  templateFunc?: ?(string => string),
};

Html.defaultProps = {
  mobileHtmlContent: null,
  templateFunc: null,
};

const mobile = {
  display: [ { from: 'l', value: 'none', }, ],
};

const desktop = {
  display: [ { until: 'l', value: 'none', }, ],
};

export default function Html({ htmlContent, mobileHtmlContent, templateFunc, }: Props) {
  return (
    <React.Fragment>
      { mobileHtmlContent
        ? htmlContent.map((obj, idx) => <HtmlNode miscStyles={desktop} key={`d_${idx.toString()}`} {...obj} />)
          .concat(mobileHtmlContent.map((obj, idx) => <HtmlNode templateFunc={templateFunc} miscStyles={mobile} key={`m_${idx.toString()}`} {...obj} />))
        : htmlContent
          ? htmlContent.map((obj, idx) => <HtmlNode templateFunc={templateFunc} key={String(idx)} {...obj} />)
          : null}
    </React.Fragment>
  );
}
