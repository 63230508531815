// @flow
import React from 'react';
import useEnvironment from '../../hooks/useEnvironment';
import Debug from '../Debug/Debug';

export default function BSException({ children, }: { children: string, }) {
  const env = useEnvironment();

  const Wrapper = env === 'prod' ? Debug : React.Fragment;

  return (
    <Wrapper>
      <div style={{ color: 'orangered', fontWeight: 'bold', }}>
        {`${children} is currently not adapted for BS`}
      </div>
    </Wrapper>
  );
}
