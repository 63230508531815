export const photoBlogHeader = {
  __typename: 'HtmlNode',
  tag: 'h2',
  content: '<strong>על הבלוג</strong> <span>| חשיפה</span>',
};

export const photoBlogBody = {
  __typename: 'HtmlNode',
  tag: 'div',
  content: '"חשיפה" בלוג הצילום של ״הארץ״ נוסד בשנת 2013 והינו בלוג הצילום הראשון מסוגו בעיתונות הישראלית. הבלוג מעניק במה לצלמים וצלמות מהארץ ומהעולם ושואף להרחיב את נקודת המבט על מגוון סוגיות אישיות, חברתיות וגלובליות.<br /><br /> יוזם ועורך הבלוג: דניאל צ\'צ\'יק - צלם "הארץ" מאז שנת 2003 המתמקד בפרויקטים דוקומנטריים ובפורטרטים. עבודותיו מוצגות במיטב המוזיאונים בארץ וכן בתערוכות ובירידים ברחבי העולם.<br /><br /> ניתן להתרשם מעבודותיו באתר: <a class="#a#" href="http://danieltchetchik.com/">danieltchetchik.com. </a>הצעות לפרסום ניתן לשלוח ל: <a class="#a#" href="mailto:danielt@haaretz.co.il">danielt@haaretz.co.il</a> צילומים ברוחב 2,500 פיקסלים וטקסט עם תיאור קצר של עד 400 מילה. יש להעביר את החומרים ב- wetransfer.',
};

export const familyAlbumHeader = [
  {
    tag: 'strong',
    content: [
      {
        tag: '#text',
        attributes: [
          {
            key: 'text',
            value: 'אלבום משפחתי',
          },
        ],
      },
    ],
  },
];

export const familyAlbumBody = [
  {
    tag: 'p',
    content: [
      {
        tag: '#text',
        attributes: [
          {
            key: 'text',
            value: 'בלוג הצילום "אלבום משפחתי", הוא פרי שיתוף פעולה בין "חשיפה", בלוג הצילום של "הארץ" ',
          },
        ],
      },
      {
        tag: 'a',
        attributes: [
          {
            key: 'href',
            value: 'https:\/\/www.instagram.com/haaretz_exposure/',
          },
        ],
        content: [
          {
            tag: '#text',
            attributes: [
              {
                key: 'text',
                value: '(אינסטגרם)',
              },
            ],
          },
        ],
      },
      {
        tag: '#text',
        attributes: [
          {
            key: 'text',
            value: ' , לבין מדור משפחה ב"הארץ". הבלוג, שגובש בתקופת מגיפה הקורונה ב-2021, מתעד רגעים אינטימיים בתרבות המשפחתית - משקופיות נושנות ועד צילומים תיעודיים. פרטים נוספים - ',
          },
        ],
      },
      {
        tag: 'a',
        attributes: [
          {
            key: 'href',
            value: 'https://www.haaretz.co.il/family/2021-03-16/ty-article-magazine/0000017f-e814-dc7e-adff-f8bd62be0000',
          },
        ],
        content: [
          {
            tag: '#text',
            attributes: [
              {
                key: 'text',
                value: 'קול קורא',
              },
            ],
          },
        ],
      },
      {
        tag: '#text',
        attributes: [
          {
            key: 'text',
            value: '. עורכים: דניאל צ\u0027צ\u0027יק ועלמא אליוט הופמן.',
          },
        ],
      },
      {
        tag: 'br',
      },
      {
        tag: '#text',
        attributes: [
          {
            key: 'text',
            value: '\r\nהצעות לפרסום ניתן לשלוח ל: ',
          },
        ],
      },
      {
        tag: 'a',
        attributes: [
          {
            key: 'href',
            value: 'mailto:family@haaretz.co.il',
          },
        ],
        content: [
          {
            tag: '#text',
            attributes: [
              {
                key: 'text',
                value: 'family@haaretz.co.il',
              },
            ],
          },
        ],
      },
      {
        tag: 'br',
      },
      {
        tag: '#text',
        attributes: [
          {
            key: 'text',
            value: 'צילומים ברוחב 2,500 פיקסלים וטקסט עם תיאור קצר של עד 400 מילה. יש להעביר את החומרים ב-',
          },
        ],
      },
      {
        tag: 'a',
        attributes: [
          {
            key: 'href',
            value: 'https:\/\/wetransfer.com',
          },
        ],
        content: [
          {
            tag: '#text',
            attributes: [
              {
                key: 'text',
                value: 'wetransfer',
              },
            ],
          },
        ],
      },
      {
        tag: '#text',
        attributes: [
          {
            key: 'text',
            value: '.',
          },
        ],
      },
    ],
  }, ];
