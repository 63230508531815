import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import Caption from '../../../Caption/Caption';
// import FullScreenMedia from '../../../FullScreenMedia/FullScreenMedia';
import { stylesPropType, } from '../../../../propTypes/stylesPropType';
import ArticleImage from '../../../ArticleBodyImage/ArticleBodyImage';
import MagazineHeadlineImage from './MagazineHeadlineImage';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';

const propTypes = {
  isVariationB: PropTypes.bool,
  /**
   * The media object as it passed down from papi.
   */
  elementObj: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

  isPhotoBlog: PropTypes.bool,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,

  themeColor: PropTypes.string,
};

const defaultProps = {
  isVariationB: false,
  miscStyles: null,
  isPhotoBlog: false,
  themeColor: 'defaultTheme',
};

const ImageElement = MagazineHeadlineImage;

function MagazineHeadlineElement({
  elementObj,
  isVariationB,
  miscStyles,
  isPhotoBlog,
  themeColor, }) {
  const { theme, css, } = useFela();
  const getMediaComponent = useGetMediaComponent(ImageElement);
  const Element = getMediaComponent(elementObj.kind);
  const isImage = elementObj.inputTemplate === 'htz_image_Image';
  // if the Element is an image. credit prefix should set to 'צילום', issue: #1011

  return (
    <div
      className={css({
        extend: [
          // Trump all other styles with those defined in `miscStyles`
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      <ArticleImage
        {...elementObj}
        {...(isImage && elementObj.isAnimatedGif
          ? {
            shouldOpenGallery: false,
          }
          : {})}
        isHeadline
        showCaption={false}
        render={() => (
          <Element
            {...(isImage
              ? {
                isVariationB,
                image: elementObj,
              }
              : {
                image: elementObj,
                showCaption: false,
              })}
          />
        )}
      />
      <Caption
        caption={elementObj.caption || elementObj.title}
        credit={elementObj.credit || elementObj.photographer}
        color={isPhotoBlog ? [ 'neutral', '-10', ] : [ themeColor, 'captionText', ]}
        backgroundColor={isPhotoBlog ? [ 'neutral', 'base', ] : [ 'bg', 'base', ]}
        miscStyles={{
          paddingStart: '2rem',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          display: 'block',
          position: [ { from: 'l', value: 'absolute', }, ],
          insetInlineEnd: [ { from: 'l', value: '0', }, ],
          textAlign: [ { from: 'l', value: 'end', }, { until: 'l', value: 'start', }, ],
          marginInlineStart: [ { from: 'l', value: 'auto', }, ],
          maxWidth: [ { from: 'l', value: '29rem', }, ],
          paddingInlineEnd: [ { from: 'l', value: '3rem', }, { until: 'l', value: '2rem', }, ],
          paddingInlineStart: [ { until: 'l', value: '2rem', }, ],
          top: [ { from: 'l', value: isPhotoBlog ? 'calc(100%-3rem)' : '100%', }, ],
          marginTop: [ { from: 'l', value: '1rem', }, ],
          type: [ { value: -3, from: 'xl', }, ],
        }}
      />
    </div>
  );
}

MagazineHeadlineElement.propTypes = propTypes;

MagazineHeadlineElement.defaultProps = defaultProps;

export default MagazineHeadlineElement;
