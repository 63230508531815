// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import Picture from '../../../Image/Picture';
import { aspectRatios, } from '../../../Image/consts';
import { formatImageTitle, } from '../../../Image/utils';
import getImageAssets from '../../../../utils/getImageAssets';
import { buildUrl, } from '../../../../utils/buildImgURLs';
import useIsAnimationReduced from '../../../../hooks/useIsAnimationReduced';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import BSException from '../../../BSException/BSException';


type HeadlineImageProps = {
  isVariationB: boolean,
  image: ImageDataType,
};


const getInfographicAssets = image => ({
  sizes: '100vw',
  transforms: (image.files || []).map(file => ({
    width: file.width,
    aspect: 'full',
    quality: 'auto',
  })),
});

const getPictureProps = ({ isVariationB, image, bps, }) => {
  const isInfographic = image?.type === 'infographic';

  return ({
    image,
    defaultImg: {
      positionInImgArray: isInfographic ? 1 : undefined,
      sourceOptions: isInfographic ? getInfographicAssets(image) : getImageAssets({
        bps,
        aspect: 'square',
        sizes: [ { until: 's', size: '100vw', }, ],
        widths: [ 700, 600, 425, 375, ],
      }),
    },
    sources: [
      {
        from: 's',
        until: 'l',
        positionInImgArray: isInfographic ? 0 : undefined,
        sourceOptions: isInfographic ? getInfographicAssets(image) : getImageAssets({
          bps,
          aspect: isVariationB ? 'square' : 'landscape',
          sizes: '100vw',
          widths: [ 1024, 768, 600, 425, 375, ],
        }),
      },
      {
        from: 'l',
        positionInImgArray: isInfographic ? 0 : undefined,
        sourceOptions: isInfographic ? getInfographicAssets(image) : getImageAssets({
          bps,
          aspect: isVariationB ? 'square' : 'landscape',
          sizes: isVariationB
            ? [ { from: 'l', size: '50vw', }, ]
            : '100vw',
          widths: isVariationB
            ? [ 960, 640, 512, ]
            : [ 1920, 1280, 1024, ],
        }),
      },
    ],
  });
};

const animatedImageWrapperStyles = ({ width, height, theme, }) => ({
  height: '0',
  paddingBottom: `${(height / width) * 100}%`,
  position: 'relative',
  width: '100%',
  backgroundColor: theme.color('neutral', '-7'),
});

const animatedImageInnerStyles = {
  position: 'absolute',
  top: '0',
  start: '0',
  width: '100%',
  height: '100%',
  objectFit: 'fill',
};

function HeadlineAnimatedImage({
  isVariationB,
  image,
}: HeadlineImageProps): React.Node {
  return <BSException>HeadlineAnimatedImage</BSException>;

  const {
    contentId,
    title,
    credit,
    imgArray: [ imgData, ] = [],
  } = image;
  const [ aspect, imgWidth, ] = isVariationB ? [ 'square', 1080, ] : [ 'headline', 1250, ];
  const { height, width, } = (imgData.aspects && imgData.aspects[aspect]) || aspectRatios[aspect];
  const { css, theme, } = useFela({ height, width, });
  const wrapperClassName = css(animatedImageWrapperStyles);
  const innerClassName = css(animatedImageInnerStyles);

  const buildUrlOptions = {
    aspect,
    width: imgWidth,
    format: 'mp4',
    quality: '60',
  };
  const src = buildUrl(contentId, imgData, buildUrlOptions);

  const isAnimationReduced = useIsAnimationReduced();
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (isAnimationReduced) {
        video.pause();
        video.currentTime = 0;
      }
      else if (video.paused) {
        video.play();
      }
    }
  }, [ isAnimationReduced, ]);

  return (
    <div className={wrapperClassName}>
      <video
        ref={videoRef}
        playsInline
        loop
        autoPlay={!isAnimationReduced}
        muted
        controls={false}
        src={src}
        title={formatImageTitle({ title, credit, theme, })}
        className={innerClassName}
      />
    </div>
  );
}

function MagazineHeadlineImage({ isVariationB, ...props }: HeadlineImageProps): React.Node {
  const image = props?.image || props;

  const { bps, } = useFela().theme;

  if (!image) return null;

  return image.inputTemplate === 'com.tm.AnimatedGif' || image.isAnimatedGif ? (
    <HeadlineAnimatedImage isVariationB={isVariationB} image={image} />
  ) : (
    <Picture
      {...{
        image,
        ...getPictureProps({ isVariationB, bps, image, }),
      }}
    />
  );
}

export default MagazineHeadlineImage;
