import getImageAssets from '../../../../utils/getImageAssets';

export default (aspect, isFullScreen, viewMode, position, bps) => {
  if (viewMode === 'landscape') {
    return getImageAssets({
      aspect,
      bps,
      sizes: '100vw',
      widths: [ 1920, 1440, 1028, 768, 600, 425, 375, ],
    });
  }

  if (position === 'left' || position === 'right') {
    return getImageAssets({
      bps,
      aspect,
      sizes: isFullScreen ? '100vw' : [
        { from: 'xl', size: '294px', },
        { from: 'l', size: '234px', },
        { from: 'm', size: '564px', },
        { from: 's', size: '600px', },
        { until: 's', size: 'calc(100vw - 24px)', },
      ],
      widths: [ 1920, 1440, 1280, 1028, 768, 600, 425, 375, 295, 235, ],
    });
  }

  if (position === 'middle-wide') {
    return getImageAssets({
      bps,
      aspect,
      sizes: isFullScreen ? '100vw' : [
        { from: 'xl', size: '1232px', },
        { from: 'l', size: '978px', },
        { from: 'm', size: '660px', },
        { from: 's', size: '576px', },
        { until: 's', size: 'calc(100vw - 24px)', },
      ],
      widths: [ 1920, 1440, 1280, 1028, 768, 600, 425, 375, ],
    });
  }

  if (position === 'middle-left' || position === 'middle-right') {
    return getImageAssets({
      bps,
      aspect,
      sizes: isFullScreen ? '100vw' : [
        { from: 'xl', size: '917px', },
        { from: 'l', size: position === 'middle-right' ? '726px' : '846px', },
        { from: 'm', size: '660px', },
        { from: 's', size: '576px', },
        { until: 's', size: 'calc(100vw - 24px)', },
      ],
      widths: [ 1920, 1440, 1280, 1028, 918, 726, 600, 425, 375, ],
    });
  }

  if (position === 'center' || position === 'midCenterPosition') {
    return getImageAssets({
      bps,
      aspect,
      sizes: isFullScreen ? '100vw' : [
        { from: 'xl', size: '602px', },
        { from: 'l', size: '594px', },
        { from: 'm', size: '564px', },
        { from: 's', size: '576px', },
        { until: 's', size: 'calc(100vw - 24px)', },
      ],
      widths: [ 1920, 1440, 1280, 1028, 918, 726, 600, 425, 375, ],
    });
  }

  return getImageAssets({
    bps,
    aspect,
    sizes: '100vw',
    widths: [ 1920, 1440, 1280, 1028, 768, 600, 425, 375, ],
  });
};
