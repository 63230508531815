/* global fetch, Headers */
import React from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';

import LayoutContainer from '../../../PageLayout/LayoutContainer';
import ArticleHeaderMeta from '../../../ArticleHeader/ArticleHeaderMeta';
import MagazineHeadlineElement from './MagazineHeadlineElement';
import MagazineShareBar from './MagazineShareBar';
import MagazineHeaderText from './MagazineHeaderText';
import useArticleHeaderData from '../../../../hooks/Page/useArticleHeaderData';
import useIsLabel from '../../../../hooks/Page/useIsLabel';
import useShouldDisplayOpeningElement from '../../useShouldDisplayOpeiningElement';

// eslint-disable-next-line react/prop-types
const HeaderElementCont = ({ bgc, children, miscStyles, theme, }) => (
  <LayoutContainer
    bgc={bgc}
    miscStyles={{
      maxWidth: [ { from: 'xl', value: '148rem', }, { from: 'l', until: 'xl', value: '164rem', }, ],
      paddingRight: [ { until: 'm', value: '2rem', }, ],
      paddingLeft: [ { until: 'm', value: '2rem', }, ],
      ...miscStyles,
    }}
  >
    {children}
  </LayoutContainer>
);

Header.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
  isPhotoBlog: PropTypes.bool.isRequired,
  customExclusive: PropTypes.string,
  customExclusiveHref: PropTypes.string,
  headlineElement: PropTypes.shape({}).isRequired,
  /**
   * Should the magazine article header display in bet variation
   */
  variationB: PropTypes.bool,
  themeColor: PropTypes.string,
  /** various layout consts */
  magazineLayout: PropTypes.shape({
    maxWidth: PropTypes.shape({
      m: PropTypes.string.isRequired,
      ml: PropTypes.string.isRequired,
    }).isRequired,
    innerPadding: PropTypes.shape({
      s: PropTypes.string.isRequired,
      m: PropTypes.string.isRequired,
      ml: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  showDate: PropTypes.bool,
  shareUrl: PropTypes.string,
};

Header.defaultProps = {
  variationB: false,
  customExclusive: null,
  customExclusiveHref: null,
  themeColor: 'defaultTheme',
  showDate: true,
  shareUrl: null,
};

function Header({
  isPhotoBlog,
  canonicalUrl,
  shareUrl,
  customExclusive,
  customExclusiveHref,
  headlineElement,
  magazineLayout,
  variationB,
  themeColor,
  showDate,
}) {
  const { css, theme, } = useFela();
  const header = useArticleHeaderData();
  const isLabel = useIsLabel();
  const showOpeningElement = useShouldDisplayOpeningElement(headlineElement);

  const { title, subtitle, } = header || {};

  return (
    <>
      <header
        className={css({
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          color: theme.color(themeColor, 'textColor'),
          backgroundColor: theme.color(themeColor, 'backgroundColor'),
          position: 'relative',
          extend: [
            theme.mq(
              { from: 'l', },
              { marginBottom: isPhotoBlog ? '' : '6rem',
                ...(variationB ? {} : { paddingTop: '6rem', }),
                paddingBottom: isPhotoBlog ? '6rem' : '', }
            ),
          ],
        })}
        data-test="articleHeader"
      >
        <HeaderElementCont
          bgc={theme.color(themeColor, 'backgroundColor')}
          miscStyles={{
            ...theme.mq(
              { from: 'l', },
            ),
            ...(variationB
              ? {
                width: '100%',
                // need to specify all breakpoints to beat specificity
                maxWidth: [
                  { from: 's', until: 'm', value: '100%', },
                  { from: 'm', until: 'l', value: '100%', },
                  { from: 'l', until: 'xl', value: '100%', },
                  { from: 'xl', value: '100%', },
                ],
                paddingRight: 0,
                paddingLeft: 0,
                display: [ { from: 'l', value: 'flex', }, ],
                alignItems: [ { from: 'l', value: 'center', }, ],
              }
              : {
                maxWidth: [ { from: 'xl', value: '144rem', }, ],
                marginTop: theme.magazineArticleStyle.variationA.magazineHeaderMarginTop,
              }),
          }}
        >
          <div
            className={css({
              marginInlineStart: 'auto',
              marginInlineEnd: 'auto',
              extend: [
                theme.mq(
                  { until: 'l', },
                  { paddingInlineStart: '2rem', paddingInlineEnd: '2rem', marginTop: '4rem', }
                ),
                theme.mq({ until: 'm', }, { maxWidth: '100rem', }),
                theme.mq({ from: 'm', until: 'l', }, { maxWidth: '110rem', }),
                ...(variationB
                  ? [
                    theme.mq(
                      { from: 'l', },
                      {
                        paddingInlineStart: '4rem',
                        paddingInlineEnd: '4rem',
                        maxWidth: theme.magazineArticleStyle.variationB.headerTextMaxWidth,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }
                    ),
                  ]
                  : []),
              ],
            })}
          >
            <MagazineHeaderText
              isPhotoBlog={isPhotoBlog}
              subtitle={subtitle}
              title={title}
              variationB={variationB}
              customExclusive={customExclusive}
              customExclusiveHref={customExclusiveHref}
              titleMiscStyles={{ position: 'relative', }}
            />
            {/* under text share bar */}
            {variationB ? (
              <MagazineShareBar
                isPhotoBlog={isPhotoBlog}
                title={title}
                canonicalUrl={shareUrl || canonicalUrl}
                themeColor={themeColor}
                miscStyles={{
                  marginTop: theme.magazineArticleStyle.variationB.shareBarMarginTop,
                }}
              />
            ) : null}
          </div>
          {headlineElement && variationB && showOpeningElement ? (
            <MagazineHeadlineElement
              themeColor={themeColor}
              isPhotoBlog={isPhotoBlog}
              isVariationB
              elementObj={headlineElement}
              miscStyles={{
                alignSelf: 'flex-start',
                marginTop: [ { until: 'l', value: '3rem', }, ],
                width: [ { from: 'l', value: '50%', }, ],
                display: [ { from: 'l', value: 'inline-block', }, ],
                textAlign: 'start',
              }}
            />
          ) : null}
        </HeaderElementCont>
        {variationB ? null : (
          <HeaderElementCont
            bgc={theme.color(themeColor, 'backgroundColor')}
            miscStyles={{
              // need this in order to run over specificity with mq's
              maxWidth: [
                { from: 'm', until: 'l', value: '120rem', },
                { from: 'xl', value: '145rem', },
                { from: 'l', until: 'xl', value: '145rem', },
              ],
              marginRight: 'auto',
              marginLeft: 'auto',
              marginTop: [ { until: 'l', value: '1rem', }, ],
            }}
          >
            <MagazineShareBar
              isPhotoBlog={isPhotoBlog}
              title={title}
              isLabel={isLabel}
              canonicalUrl={shareUrl || canonicalUrl}
              themeColor={themeColor}
              miscStyles={{ marginTop: theme.magazineArticleStyle.variationA.shareBarMarginTop, }}
            />
          </HeaderElementCont>
        )}
        {headlineElement && !variationB && showOpeningElement ? (
          <MagazineHeadlineElement
            themeColor={themeColor}
            isPhotoBlog={isPhotoBlog}
            elementObj={headlineElement}
            miscStyles={{
              marginTop: [ { from: 'm', value: '5rem', }, { until: 'm', value: '2rem', }, ],
              marginBottom: [ { until: 'm', value: 0, }, ],
              textAlign: 'start',
            }}
          />
        ) : null}
      </header>
      <div className={css({ backgroundColor: isPhotoBlog ? theme.color(themeColor, 'backgroundColor') : theme.color('bg', 'base'), })}>
        <HeaderElementCont
          showDate={showDate}
          bgc={isPhotoBlog ? theme.color(themeColor, 'backgroundColor') : theme.color('bg', 'base')}
          miscStyles={{
            paddingTop: theme.magazineArticleStyle.headerMetaPaddingTop,
            paddingInlineStart: [
              { until: 's', value: magazineLayout.innerPadding.s, },
              { from: 's', until: 'm', value: magazineLayout.innerPadding.m, },
              { from: 'm', until: 'l', value: magazineLayout.innerPadding.ml.start, },
            ],
            paddingInlineEnd: [
              { until: 's', value: magazineLayout.innerPadding.s, },
              { from: 's', until: 'm', value: magazineLayout.innerPadding.m, },
              { from: 'm', until: 'l', value: magazineLayout.innerPadding.ml.end, },
            ],
            order: [ { until: 'l', value: 2, }, ],
            maxWidth: [
              { from: 's', until: 'm', value: magazineLayout.maxWidth.m, },
              { from: 'm', until: 'l', value: magazineLayout.maxWidth.ml, },
            ],
            textAlign: 'start',
            paddingBottom: theme.magazineArticleStyle.headerMetaPaddingBottom,
          }}
        >
          <ArticleHeaderMeta
            isPhotoBlog={isPhotoBlog}
            showDate={showDate}
            miscStyles={{
              flexDirection: 'column',
              display: [ { until: 'l', value: 'flex', }, { from: 'l', value: 'none', }, ],
              justifyContent: [
              // { from: 's', until: 'l', value: 'center', },
                { from: 's', until: 'l', value: 'flex-start', },
              ],
              textAlign: [ { until: 's', value: 'start', }, ],
            }}
            variationMq={{
              a: { until: 'l', },
              c: { from: 'l', },
            }}
          />
        </HeaderElementCont>
      </div>
    </>
  );
}

export default Header;
