import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, parseTypographyProp, } from '@haaretz/htz-css-tools';
import HtzLink from '../../../HtzLink/HtzLink';
import { stylesPropType, } from '../../../../propTypes/stylesPropType';


HeaderText.propTypes = {
  /** Class(es) to be added to the DOM element.
   * Can be passed by manually, or by Fela's createComponent
   */
  className: PropTypes.string,
  isPhotoBlog: PropTypes.bool.isRequired,
  /** Headline (title) of an Article */
  title: PropTypes.string.isRequired,
  /** Subtitle of an Article */
  subtitle: PropTypes.string,
  customExclusive: PropTypes.string,
  customExclusiveHref: PropTypes.string,
  /** is the magazine design variation b */
  variationB: PropTypes.bool.isRequired,
  Tag: PropTypes.string,
  exclusiveMiscStyles: stylesPropType,
  subtitleMiscStyles: stylesPropType,
  titleMiscStyles: stylesPropType,
};

HeaderText.defaultProps = {
  className: null,
  subtitle: null,
  customExclusive: null,
  customExclusiveHref: null,
  exclusiveMiscStyles: null,
  subtitleMiscStyles: null,
  titleMiscStyles: null,
  Tag: 'div',
};

const titleStyle = ({ theme, variationB, titleMiscStyles, }) => ({
  display: 'inline',
  fontWeight: 300,
  extend: [
    (variationB
      ? parseTypographyProp(theme.magazineArticleStyle.variationB.titleType, theme.type)
      : parseTypographyProp(theme.magazineArticleStyle.variationA.titleType, theme.type)),
    ...(titleMiscStyles
      ? parseStyleProps(titleMiscStyles, theme.mq, theme.type)
      : []),
  ],
});

const subtitleStyle = ({ theme, variationB, subtitleMiscStyles, }) => ({
  marginTop: '3rem',
  marginInlineStart: 'auto',
  marginInlineEnd: 'auto',
  maxWidth: '145rem',
  extend: [
    theme.mq({ from: 's', }, { fontWeight: 700, }),
    (variationB
      ? parseTypographyProp(theme.magazineArticleStyle.variationB.subTitleType, theme.type)
      : parseTypographyProp(theme.magazineArticleStyle.variationA.subTitleType, theme.type)),
    theme.mq({ from: 'l', until: 'xl', }, { marginTop: '3rem', }),
    ...(subtitleMiscStyles
      ? parseStyleProps(subtitleMiscStyles, theme.mq, theme.type)
      : []),
  ],
});

function HeaderText({
  isPhotoBlog,
  title,
  subtitle,
  className,
  variationB,
  Tag,
  customExclusive,
  customExclusiveHref,
  exclusiveMiscStyles,
  subtitleMiscStyles,
  titleMiscStyles,
}) {
  const { css, theme, } = useFela({ variationB, subtitleMiscStyles, titleMiscStyles, });
  return (
    <Tag className={className}>
      {customExclusive ? (
        <HtzLink
          href={customExclusiveHref}
          className={css({
            display: 'block',
            fontWeight: 'bold',
            color: theme.color(...(isPhotoBlog ? [ 'quaternary', ] : [ 'magazine', 'headerText', ])),
            ':hover': {
              color: theme.color(...(isPhotoBlog ? [ 'quaternary', ] : [ 'magazine', 'headerText', ])),
            },
            ':visited': {
              color: theme.color(...(isPhotoBlog ? [ 'quaternary', ] : [ 'magazine', 'headerText', ])),
            },
            marginBottom: '2rem',
            extend: [
              theme.mq({ until: 's', }, { marginBottom: '3rem', }),
              ...(exclusiveMiscStyles
                ? parseStyleProps(exclusiveMiscStyles, theme.mq, theme.type)
                : []),
            ],
          })}
        >
          {customExclusive}
        </HtzLink>
      ) : null}
      <h1 className={css(titleStyle)} data-test="articleHeaderTitle">{title}</h1>
      {subtitle ? <p className={css(subtitleStyle)} data-test="articleHeaderSubtitle">{subtitle}</p> : null}
    </Tag>
  );
}

export default HeaderText;
