// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import * as texts from './photoBlogDescriptionText';
import H from '../../../AutoLevels/H';
import Html from '../../../Html/Html';
import HtmlNode from '../../../HtmlNode/HtmlNode';
import IconCamera from '../../../Icon/icons/IconCamera';
import { parseLayout, } from '../utils';
import type { MagazineLayoutType, } from '../types';

type Props = {
  magazineLayout: MagazineLayoutType,
  photoBlogChannel?: string,
};

PhotoBlogDescription.defaultProps = {
  photoBlogChannel: 'photoBlog',
};

function headerStyle({ theme, }) {
  return {
    fontWeight: 300,
    '& strong': {
      fontWeight: 700,
    },
  };
}

function textStyle({ theme, }) {
  return {
    fontWeight: 300,
    marginTop: '1rem',
    '& p': {
      ...theme.type(0),
    },
    '& a:link': {
      color: 'inherit',
    },
    '& a:hover': {
      color: 'inherit',
    },
    '& a:visited': {
      color: 'inherit',
    },
    '& a': {
      textDecoration: 'underline',
      border: 'none',
      color: 'inherit',
    },
    extend: [
      theme.type(0),
    ],
  };
}

function PhotoBlogDescription({ magazineLayout, photoBlogChannel, }: Props) {
  const { css, theme, } = useFela();
  const channel = photoBlogChannel || 'photoBlog';
  const isPhotoBlog = channel === 'photoBlog';

  const header = texts[`${channel}Header`];
  const text = texts[`${channel}Body`];

  return (
    <div
      className={css({
        marginTop: '14rem',
        marginBottom: '14rem',
        extend: [ ...parseStyleProps(parseLayout(magazineLayout), theme.mq, theme.type), ],
      })}
    >
      <div
        className={css({
          backgroundColor: '#2d2d2d',
          // paddingLeft: '3rem',
          // paddingRight: '3rem',
          transform: 'skewY(-4deg)',
          transformOrigin: 'top left',
        })}
      >
        <div
          className={css({
            paddingBottom: '5rem',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            paddingTop: '2rem',
            backgroundColor: '#2d2d2d',
            transform: 'skewY(4deg)',
            transformOrigin: 'top left',
          })}
        >
          <div
            className={css({
              backgroundColor: theme.color('quaternary'),
              borderRadius: '50%',
              width: '10rem',
              height: '10rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.color('neutral', '-1'),
              position: 'absolute',
              top: '-9rem',
              extend: [
                theme.mq({ from: 's', until: 'l', }, { top: '-10rem', }),
                theme.mq({ from: 'l', }, { top: '-11rem', }),
              ],
            })}
          >
            <IconCamera size={7} />
          </div>
          {isPhotoBlog
            ? (
              <>
                <HtmlNode className={css(headerStyle)} {...header} />
                <HtmlNode className={css(textStyle)} {...text} />
              </>
            ) : (
              <>
                <H className={css(headerStyle)}>
                  <Html htmlContent={header} />
                </H>
                <div className={css(textStyle)}>
                  <Html htmlContent={text} />
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default PhotoBlogDescription;
