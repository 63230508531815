// @flow
import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';
import log from 'loglevel';
import { parseComponentProp, } from '@haaretz/htz-css-tools';
import type { Node, } from 'react';

import { useUser, } from '../../../User/UserDispenser';
import useGetComponent from '../../../../hooks/GetComponentContext/useGetComponent';
import ArticleImage from '../../../ArticleBodyImage/ArticleBodyImage';
import Caption from '../../../Caption/Caption';
import NoSSR from '../../../NoSSR/NoSSR';
import PhotoBlogDescription from './PhotoBlogDescription';
import buildImgOptions from './magazineArticleBodyBuildImgOptions';
import BackToLabelSection from '../../../BackToLabelSection/BackToLabelSection';
import { parseLayout, } from '../utils';
import Tldr from '../../../Tldr/Tldr';
import type { Context, } from '../../../../flowTypes/articleContentType';
import EventTracker from '../../../../utils/EventTracker';
import isOmnyCustomPlayer from '../../../../utils/isOmnyCustomPlayer';
import getArticleBodyComponentId from '../../../../utils/getArticleBodyComponentId';
import TrinityPlayer from '../../../Scripts/TrinityPlayer';
import type { MagazineLayoutType, } from '../types';
import Tags from '../../../Tags/Tags';
import { clearBody, } from '../../../ArticleBody/ArticleBody';
import useWebViewChecker from '../../../../hooks/useWebViewChecker';
import useBreadcrumbsData from '../../../../hooks/Page/useBreadcrumbsData';
import useIsLabel from '../../../../hooks/Page/useIsLabel';
import useIsBlock from '../../../../hooks/useIsBlock';
import { useExcludeResponsiveRender, useExcludeUserTypes, usePlatformRender, } from '../../../../utils/getElementsFactory';
import RainbowPaywallSlot from '../../../Marketing/RainbowPaywallSlot';

const mediaQueryCallback = (prop, value) => ({ [prop]: value, });

type FigureProps = {
  lastItem: boolean,
  isInstagram?: boolean,
  isPinterest?: boolean,
  children: Node,
};

Figure.defaultProps = {
  isInstagram: false,
  isPinterest: false,
};

function Figure({ lastItem, children, isInstagram, isPinterest, }: FigureProps) {
  const { css, theme, } = useFela();

  return (
    <figure
      className={css({
        ...(isInstagram
          ? {
            '& iframe': {
              maxWidth: '100% !important',
              minWidth: '0 !important',
            },
          }
          : {}),
        ...(isPinterest
          ? {
            '& span[class^=PIN_][class*=_embed_pin]': {
              minWidth: '0 !important',
            },
          }
          : {}),
        ...(!lastItem
          ? {
            ...parseComponentProp(
              'marginBottom',
              theme.articleStyle.body.marginBottom,
              theme.mq,
              mediaQueryCallback
            ),
          }
          : {}),
      })}
      as="figure"
    >
      {children}
    </figure>
  );
}

type magazineArticleImageProps = {
  lastItem: boolean,
  isPhotoBlog: boolean,
  context: Context,
};
function MagazineArticleImage({
  isPhotoBlog,
  lastItem,
  context,
}: magazineArticleImageProps) {
  const { theme, } = useFela();
  const captionProps = {
    ...(isPhotoBlog ? { color: [ 'bodyText', 'inverse', ], } : {}),
    ...(context.position === 'midMax'
      ? { miscStyles: { paddingStart: '2rem', marginTop: '1rem', }, }
      : {}),
  };
  const { position, } = context.image || context || {};

  const isLeftOrRight = [ 'left', 'right', ].includes(position);
  const isMidMaxPosition = [ 'middle-max', ].includes(position);

  return (
    <ArticleImage
      captionProps={captionProps}
      key={context.contentId}
      lastItem={lastItem}
      {...context}
      isMagazineArticle
      isLeftOrRight={isLeftOrRight}
      isMidMaxPosition={isMidMaxPosition}
      imgOptions={(aspect, isFullScreen) => buildImgOptions(
        aspect,
        isFullScreen,
        context.viewMode,
        context.position,
        theme.bps
      )
      }
    />
  );
}

const BuildComponent = ({
  context,
  index,
  isLastItem,
  magazineLayout,
  isPhotoBlog,
  getComponent,
  isLabel,
  theme,
}: any) => {
  const appearByMQ = useExcludeResponsiveRender(context?.preventRender);
  const appearByPlatform = usePlatformRender(context?.excludePlatform);
  const appearByUserType = useExcludeUserTypes(context?.excludeUserTypes);

  if (!context) {
    log.error(`Received no element context at index ${index}`);
    return null;
  }

  if (!(appearByMQ && appearByPlatform && appearByUserType)) return null;

  const uniqueId = getArticleBodyComponentId(context);

  const Component = getComponent(uniqueId, context);

  switch (uniqueId) {
    case 'image':
    case 'htz_image_Image':
    case 'infographic':
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={context}
          magazineLayout={magazineLayout}
        >
          <MagazineArticleImage
            context={context}
            lastItem={isLastItem}
            isPhotoBlog={isPhotoBlog}
          />
        </MagazineContentWrapper>
      );
    case 'EmbedRichTextElement':
    case 'Embed':
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={{
            ...context,
            ...(context.embedType === 'omniStudio'
              ? { position: 'mid-center-position', }
              : {}),
          }}
          magazineLayout={magazineLayout}
        >
          <Figure
            key={context.contentId}
            lastItem={isLastItem}
            isInstagram={context.embedType === 'instagram'}
            isPinterest={context.embedType === 'pin'}
          >
            <Component {...context} />
          </Figure>
        </MagazineContentWrapper>
      );
    case 'Gallery':
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={context}
          magazineLayout={magazineLayout}
        >
          <Figure lastItem={isLastItem}>
            <Component
              {...context}
              imgOptions={(aspect, isFullScreen) => buildImgOptions(aspect, isFullScreen)
              }
            />
            {context.title || context.caption || context.credit ? (
              <Caption
                caption={context.title || context.caption}
                credit={context.credit}
              />
            ) : null}
          </Figure>
        </MagazineContentWrapper>
      );
    case 'InteractiveRichTextElement':
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={{
            ...context,
            ...context.content,
          }}
          magazineLayout={magazineLayout}
        >
          <Figure lastItem={isLastItem}>
            <Component {...context} />
            {context.title || context.caption || context.credit ? (
              <Caption
                caption={context.title || context.caption}
                credit={context.credit}
              />
            ) : null}
          </Figure>
        </MagazineContentWrapper>
      );
    case 'interactive':
    case 'Video': // eslint-disable-line no-case-declarations
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={{
            ...context,
            ...context.properties,
          }}
          magazineLayout={magazineLayout}
        >
          <Figure lastItem={isLastItem}>
            <Component {...context} />
            {context.title || context.caption || context.credit ? (
              <Caption
                caption={context.title || context.caption}
                credit={context.credit}
              />
            ) : null}
          </Figure>
        </MagazineContentWrapper>
      );
    case 'QuoteRichTextElement':
    case 'VisualInfo':
    case 'QAndA':
    case 'ColumnsModel':
    case 'Timeline':
    case 'Ids':
    case 'ListInfo':
    case 'Chat':
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={context}
          magazineLayout={magazineLayout}
        >
          <Component
            miscStyles={{
              ...(isLastItem
                ? null
                : { marginBottom:
            [
              { until: 's', value: '4rem', },
              { from: 's', value: '5rem', },
            ],
                marginTop:
            [
              { until: 's', value: '4rem', },
              { from: 's', value: '5rem', },
            ], }
              ), }}
            {...context}
          />
        </MagazineContentWrapper>
      );
    case 'AdSlot':
    case 'AdSlotRichTextElement':
      // todo: add miscStyles and then textAlign end on large bp
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={context}
          magazineLayout={magazineLayout}
        >
          <Component
            key={context.contentId}
            {...context}
            {...context.properties}
          />
        </MagazineContentWrapper>
      );
    case 'TableScoreRichTextElement':
      return (
        <MagazineContentWrapper
          component={context}
          magazineLayout={magazineLayout}
        >
          <Component
            {...context}
            {...context.properties}
            miscStyles={{
              marginTop: '4rem',
              marginBottom: '4rem',
            }}
          />
        </MagazineContentWrapper>
      );
    case 'RegistrationRichTextElement':
      return (
        <NoSSR key={context.contentId}>
          <MagazineContentWrapper
            component={context}
            magazineLayout={magazineLayout}
          >
            <Component
              {...context}
              {...context.properties}
              miscStyles={{
                marginTop: '4rem',
                marginBottom: '4rem',
              }}
            />
          </MagazineContentWrapper>
        </NoSSR>
      );
    case 'CardRichTextElement':
    case 'TenRecipes':
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={{ ...context, position: 'middle-wide', }}
          magazineLayout={magazineLayout}
        >
          <Component {...context} />
        </MagazineContentWrapper>
      );
    case 'com.polobase.audioEmbed':
    case 'OmnyStudio':
    case 'articleNarration':
    case 'PodcastEpisode':
      return (
        <MagazineContentWrapper
          key={context.contentId}
          component={{}}
          magazineLayout={magazineLayout}
        >
          <Component
            fileUrl={context.fileUrl || (context.settings || {}).fileUrl}
            contentName={context.contentName}
            caption={context.caption}
            channelLinks={(context.settings || {}).channelLinks}
            isOmny={isOmnyCustomPlayer(context)}
            loadAfterFirstClick={isOmnyCustomPlayer(context)}
            channelName={(context.settings || {}).channelName}
            channelLabel={(context.settings || {}).channelLabel}
            image={context.image}
            title={context.title}
            channel={context.channel}
            url={context.url}
            mobileTitle={context.mobileTitle}
            miscStyles={isLastItem ? null : { marginBottom: theme.articleStyle.body.marginBottom, }}
          />
        </MagazineContentWrapper>
      );
    default:
      return (
        <Component
          key={context.contentId || uniqueId + index}
          isLabel={isLabel}
          {...context}
          {...(uniqueId === 'RelatedArticles' ? { isLabel, } : {})}
          miscStyles={{
            ...parseLayout(magazineLayout),
            ...([ 'RelatedArticles', 'SeriesArticles', ].includes(uniqueId)
              ? { marginTop: 0, marginBottom: '3rem', }
              : {}),
          }}
          {...(uniqueId === 'HtmlNode' && (context.tag === 'p' || context.tag === 'a' || context.tag === 'h4')
            ? {
              renderFirstImpression: !isLastItem,
            }
            : {})}
        />
      );
  }
};

const wrapperClear = {
  'middle-right': { clear: 'right', },
  'middle-left': { clear: 'left', },
  'middle-wide': { clear: 'both', },
  left: {},
  right: {},
};

type MagazineContentWrapperProps = {
  children: Node,
  magazineLayout: MagazineLayoutType,
  component: Context,
};

function MagazineContentWrapper({
  children,
  magazineLayout: { maxWidth, innerPadding, spacing, },
  component,
}: MagazineContentWrapperProps) {
  const { css, theme, } = useFela();
  const { position, viewMode, } = component;
  // if position is left or right we need a absolute position container to take the element out of the flow
  const isAbsolutePosition = position === 'left' || position === 'right';
  const InnerCont = !isAbsolutePosition ? Fragment : 'div';

  return (
    <div
      className={css({
        ...(viewMode === 'landscape' || position === 'middle-max'
          ? { clear: 'both', }
          : {
            marginInlineStart: 'auto',
            marginInlineEnd: 'auto',
            extend: [
              theme.mq(
                { from: 'xl', },
                {
                  marginTop: '3rem',
                  paddingRight:
                      position === 'middle-right' || position === 'middle-wide'
                        ? 0
                        : innerPadding.xl,
                  paddingLeft:
                      position === 'middle-left' || position === 'middle-wide'
                        ? 0
                        : innerPadding.xl,
                  maxWidth: maxWidth.xl,
                }
              ),
              theme.mq(
                { from: 'l', until: 'xl', },
                {
                  maxWidth: maxWidth.l,
                  paddingInlineStart:
                      position === 'middle-right' || position === 'middle-wide'
                        ? 0
                        : innerPadding.l.start,
                  paddingInlineEnd:
                      position === 'middle-left' || position === 'middle-wide'
                        ? 0
                        : innerPadding.l.end,
                }
              ),
              theme.mq(
                { from: 'm', until: 'l', },
                {
                  maxWidth: maxWidth.ml,
                  paddingInlineStart: innerPadding.ml.start,
                  paddingInlineEnd:
                    position === 'middle-right'
                    || position === 'middle-wide'
                      || position === 'middle-left'
                      ? 0
                      : innerPadding.ml.end,
                }
              ),
              theme.mq(
                { from: 's', until: 'm', },
                {
                  maxWidth: maxWidth.m,
                  paddingInlineStart: innerPadding.m,
                  paddingInlineEnd: innerPadding.m,
                }
              ),
              theme.mq(
                { from: 's', },
                {
                  ...(position ? wrapperClear[position] || {} : {}),
                }
              ),
              theme.mq(
                { until: 's', },
                {
                  marginInlineStart: spacing.s,
                  marginInlineEnd: spacing.s,
                }
              ),
            ],
          }),
      })}
    >
      <InnerCont
        {...(isAbsolutePosition
          ? {
            className: css({
              zIndex: 1,
              paddingTop: '1rem',
              extend: [
                theme.mq(
                  { from: 'xl', },
                  {
                    ...(position === 'left'
                      ? {
                        float: 'left',
                        clear: 'left',
                        marginLeft: `-${innerPadding.xl}`,
                        paddingRight: spacing.xl,
                      }
                      : {}),
                    ...(position === 'right'
                      ? {
                        float: 'right',
                        clear: 'right',
                        marginRight: `-${innerPadding.xl}`,
                        paddingLeft: spacing.xl,
                      }
                      : {}),
                    width: innerPadding.xl,
                  }
                ),
                theme.mq(
                  { from: 'l', until: 'xl', },
                  {
                    float: 'end',
                    clear: 'end',
                    marginInlineEnd: `-${innerPadding.l.end}`,
                    paddingInlineStart: spacing.l,
                    width: innerPadding.l.end,
                  }
                ),
              ],
            }),
          }
          : {})}
      >
        {children}
      </InnerCont>
    </div>
  );
}

type Props = {
  body: Context[],
  isPhotoBlog: boolean,
  photoBlogChannel?: string,
  magazineLayout: MagazineLayoutType,
  authorBio: ?Node,
  showTrinityPlayer: boolean,
};

MagazineArticleBody.defaultProps = {
  photoBlogChannel: 'photoBlog',
  authorBio: null,
  showTrinityPlayer: false,
};

function MagazineArticleBody({
  magazineLayout,
  isPhotoBlog,
  photoBlogChannel,
  authorBio,
  showTrinityPlayer,
  ...props
}: Props) {
  const getComponent = useGetComponent();
  const breadCrumbs = useBreadcrumbsData();
  const isLabel = useIsLabel();
  const isBlock = useIsBlock();

  const lineage = breadCrumbs || [];

  const { theme, } = useFela();
  const { user, } = useUser();
  const isWebView = useWebViewChecker();

  const body = clearBody({ body: props.body, isWebView, });

  return (
    <Fragment>
      <MagazineContentWrapper magazineLayout={magazineLayout} component={{}}>
        {theme.articleStyle.body.displayTldr ? <Tldr /> : null}
      </MagazineContentWrapper>
      <div data-test="articleBody" className={isPhotoBlog ? 'isPhotoBlog' : null}>
        {showTrinityPlayer && !isBlock && (
        <MagazineContentWrapper magazineLayout={magazineLayout} component={{}}>
          <EventTracker>
            {({ biImpression, biAction, }) => (
              <TrinityPlayer biImpression={biImpression} biAction={biAction} user={user} />
            )}
          </EventTracker>
        </MagazineContentWrapper>
        )}
        {body.map((component, i) => {
          const isLastItem = i === body.length - 1;
          return (
            <BuildComponent
              context={component}
              index={i}
              isLastItem={isLastItem}
              magazineLayout={magazineLayout}
              isPhotoBlog={isPhotoBlog}
              getComponent={getComponent}
              isLabel={isLabel}
              theme={theme}
            />
          );
        })}
        <MagazineContentWrapper component={{}} magazineLayout={magazineLayout}>
          <RainbowPaywallSlot id="mid-page" shouldHideContent={body.length > 0} />
        </MagazineContentWrapper>

        {isPhotoBlog ? (
          <PhotoBlogDescription magazineLayout={magazineLayout} photoBlogChannel={photoBlogChannel} />
        ) : authorBio ? (
          <MagazineContentWrapper component={{}} magazineLayout={magazineLayout}>
            {authorBio}
          </MagazineContentWrapper>
        ) : null}

        {isLabel
          ? (
            <MagazineContentWrapper component={{}} magazineLayout={magazineLayout}>
              <BackToLabelSection sectionUrl={(lineage[1] || {}).url} />
            </MagazineContentWrapper>
          ) : null}
        {isBlock ? null : (
          <MagazineContentWrapper component={{}} magazineLayout={magazineLayout}>
            <Tags isPhotoBlog={isPhotoBlog} />
          </MagazineContentWrapper>
        )}
      </div>

    </Fragment>
  );
}

export default MagazineArticleBody;
