// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { useApolloClient, } from 'react-apollo';

import ArticleLayout from '../../PageLayout/ArticleLayout';
import LayoutRow from '../../PageLayout/LayoutRow';
import MagazineArticleHeader from './MagazineElements/MagazineArticleHeader';
import MagazineArticleBody from './MagazineElements/MagazineArticleBody';
import ArticleHeaderMeta from '../../ArticleHeader/ArticleHeaderMeta';
import WideArticleLayoutRow from '../../PageLayout/WideArticleLayoutRow';
import ArticleLayoutRow from '../../PageLayout/ArticleLayoutRow';
import LayoutContainer from '../../PageLayout/LayoutContainer';
import AuthorBio from '../../AuthorBio/AuthorBio';
import useGetComponent from '../../../hooks/GetComponentContext/useGetComponent';
import { isList, } from '../../../utils/validateType';
import type { ArticleContentType, } from '../../../flowTypes/articleContentType';
import type { HeadlineElementType, } from '../../../flowTypes/HeadlineElementType';
import type { MagazineLayoutType, } from './types';
import useDarkModeChecker from '../../../hooks/useDarkModeChecker';
import useWebViewChecker from '../../../hooks/useWebViewChecker';

import useArticleHeaderData from '../../../hooks/Page/useArticleHeaderData';
import useSlotData from '../../../hooks/Page/useSlotData';
import useArticleId from '../../../hooks/Page/useArticleId';
import useArticleData from '../../../hooks/Page/useArticleData';
import useAuthorsData from '../../../hooks/Page/useAuthorsData';
import useArticleBodyData from '../../../hooks/Page/useArticleBodyData';
import useSeoData from '../../../hooks/Page/useSeoData';
import useBlogElement from '../../../hooks/Page/useBlogElement';
import useIsLabel from '../../../hooks/Page/useIsLabel';
import usePreview from '../../../hooks/Page/usePreview';
import ArticleObserver from '../../Article/ArticleObserver';
import checkSiteFromConfig from '../../../utils/checkSiteFromConfig';

const { siteAlias, } = checkSiteFromConfig();


export const magazineLayout = {
  innerPadding: {
    xl: '45rem',
    l: {
      start: '22rem',
      end: '42rem',
    },
    ml: {
      start: '16rem',
      end: '16rem',
    },
    m: '2rem',
    s: '2rem',
  },
  maxWidth: {
    xl: '176rem',
    l: '163rem',
    ml: '126rem',
    m: '100rem',
    s: '100%',
  },
  spacing: {
    xl: '3rem',
    l: '3rem',
    ml: '3rem',
    m: '4rem',
    s: '2rem',
  },
};

function MagazineArticle({
  path,
  site,
  isPhotoBlog,
  photoBlogChannel,
  showTrinityPlayer,
}: ArticleContentType) {
  const { css, theme, } = useFela();
  const getComponent = useGetComponent();
  const isWebView = useWebViewChecker();
  const client = useApolloClient();

  const articleId = useArticleId();
  const authors = useAuthorsData();
  const body = useArticleBodyData();
  const article = useArticleData();
  const header = useArticleHeaderData();
  const main = useSlotData('main');
  const seoData = useSeoData();
  const { isBlog, blog, } = useBlogElement();
  const isLabel = useIsLabel();
  const articleBodyRef = React.useRef(null);

  const { canonicalUrl: canonicalLink, ogUrl: shareUrl, } = seoData || {};

  // const {
  //   authors,
  //   body,
  //   header,
  //   headlineElement,
  //   magazineArticleViewType,
  //   commentsElementId,
  //   tldr,
  //   tldrWordCount,
  //   coverColor,
  //   enableComments,
  //   tags,
  // } = articleData;

  const articleData = article || {};
  const { commentsElementId, coverColor, viewType, openingElement, } = articleData || {};

  React.useEffect(() => {
    client.writeData({
      data: {
        commentsElementId,
      },
    });
  }, [ client, commentsElementId, ]);

  const enableComments = !!commentsElementId;

  const bgc = isPhotoBlog ? theme.color('neutral') : theme.color('bg', 'base');

  const showDate = (header || {}).showDate == null ? true : header?.showDate;

  const isDarkMode = useDarkModeChecker();
  const { isPreview, } = usePreview();

  const themeColor = isPhotoBlog || isDarkMode
    ? 'blackTheme'
    : viewType === 'side'
        && (coverColor === 'black' || coverColor === 'yellow')
      ? `${coverColor}Theme`
      : 'defaultTheme';

  const dontDisplayComments = (Array.isArray(authors)
      && authors.filter(author => author.contentId && author.contentId === '1.758')
        .length)
    || isLabel
    || isPreview
    || enableComments === false;


  let authorBio;
  if (isBlog && authors?.length === 1) {
    const author = authors[0];
    authorBio = <AuthorBio author={author} sectionName={blog} />;
  }

  const mainSlot = React.useMemo(() => ((main || []).map(element => {
    if (
      isWebView
          && (element.contentName || '').includes('article.textlink')
    ) {
      return null;
    }

    if (
      element.inputTemplate === 'com.htz.ArticleHeaderElement'
          || element.inputTemplate === 'com.tm.PageTitle'
    ) {
      return null;
    }

    const isMichelle = isList(element)
      ? element.view === 'Michelle'
      : false;

    const Element = getComponent(
      element.kind || element.inputTemplate,
      {
        ...element,
        loadPriority: element.loadPriority,
        isExpanded: element.isExpanded,
        preventRender: element.preventRender,
      }
    );
    const { properties, ...elementWithoutProperties } = element;

    if (
      element.inputTemplate === 'Outbrain'
          || element.inputTemplate
            === 'com.polobase.ClickTrackerBannersWrapper'
          || isMichelle
    ) {
      return (
        <LayoutRow
          bgc={theme.color('bg', 'base')}
          key={element.contentId}
        >
          <LayoutContainer
            miscStyles={{
              paddingInlineEnd: [
                { from: 'm', until: 'l', value: '16rem', },
                { from: 'l', value: '30rem', },
              ],
              paddingInlineStart: [
                { from: 'm', until: 'l', value: '16rem', },
                { from: 'l', value: '30rem', },
              ],
            }}
          >
            <WideArticleLayoutRow
              key={element.contentId}
              hideDivider
              {...(element.inputTemplate
                  === 'com.polobase.ClickTrackerBannersWrapper'
                ? {
                  miscStyles: {
                    display: [ { until: 's', value: 'none', }, ],
                  },
                }
                : {})}
            >
              <Element
                articleId={articleId}
                {...elementWithoutProperties}
                {...properties}
                {...(isMichelle
                  ? {
                    viewProps: {
                      articleType: 'magazine',
                      isLabel,
                      isPhotoBlog,
                    },
                  }
                  : {})}
              />
            </WideArticleLayoutRow>
          </LayoutContainer>
        </LayoutRow>
      );
    }
    const isComments = element.inputTemplate === 'CommentsPlaceHolder';
    return (
      <>
        {dontDisplayComments && isComments ? null : (
          <LayoutRow
            bgc={theme.color('bg', 'base')}
            key={element.contentId}
          >
            <LayoutContainer
              miscStyles={{
                paddingInlineEnd: [
                  { from: 'm', until: 'l', value: '16rem', },
                  { from: 'l', value: '30rem', },
                ],
                paddingInlineStart: [
                  { from: 'm', until: 'l', value: '16rem', },
                  { from: 'l', value: '30rem', },
                ],
              }}
            >
              <ArticleLayoutRow
                bgc={theme.color('bg', 'base')}
                key={element.contentId}
                {...(isComments ? {
                  title:
                            theme.articleLayoutI18n.commentSectionTitle,
                  id: 'commentsSection',
                }
                  : {})}
              >
                <Element
                  articleId={articleId}
                  {...elementWithoutProperties}
                  {...properties}
                />
              </ArticleLayoutRow>
            </LayoutContainer>
          </LayoutRow>
        )}
      </>
    );
  })), [ articleId, dontDisplayComments, getComponent, isLabel, isPhotoBlog, isWebView, main, theme, ]);


  return (
    <>
      <ArticleObserver
        observerRef={articleBodyRef}
        // The breakpoint values indicate the point at which the user has scrolled through the article,
        // and the updateFunction property specifies whether or not a specific function
        // (such as an update mutation) should be triggered when the corresponding breakpoint is reached.
        // In this example, the updateFunction will only be triggered
        // when the user reaches the breakpoint of 0.4 while scrolling through the article.
        breakpoints={[
          { breakpoint: 0.2, },
          { breakpoint: 0.4, shouldUpdate: true, },
          { breakpoint: 0.6, },
          { breakpoint: 0.8, },
          { breakpoint: 1, },
        ]}
      />
      <ArticleLayout
        isPhotoBlog={isPhotoBlog}
        rowBgc={[ 'bg', 'base', ]}
        mastheadBgc={[ themeColor, 'mastheadBg', ]}
        mastheadFullWidth
        renderPostHeader={false}
        path={path}
        site={site}
        themeColor={themeColor}
      >
        <article
          className={css({
            ...(isPhotoBlog || isDarkMode
              ? { color: theme.color('bodyText', 'inverse'), }
              : {}),
            ...(siteAlias !== 'hdc' && isWebView ? { paddingTop: 'calc(64px + max(env(safe-area-inset-top, 0), 48px))', } : {}),
          })}
        >
          <div ref={articleBodyRef}>
            <Headline
              headlineElement={openingElement}
              canonicalUrl={canonicalLink}
              shareUrl={shareUrl}
              isPhotoBlog={isPhotoBlog}
              photoBlogChannel={photoBlogChannel}
              magazineLayout={magazineLayout}
              magazineArticleViewType={viewType}
              themeColor={themeColor}
              showDate={showDate}
            />

            <LayoutRow
              bgc={bgc}
              miscStyles={{
                paddingTop: [ { until: 's', value: '2rem', }, { from: 'l', value: '1rem', }, ],
                paddingBottom: '4rem',
              }}
            >
              <MagazineArticleBody
                body={body || []}
                magazineLayout={magazineLayout}
                isPhotoBlog={isPhotoBlog}
                photoBlogChannel={photoBlogChannel}
                authorBio={authorBio}
                showTrinityPlayer={showTrinityPlayer}
              />
            </LayoutRow>
          </div>
          {mainSlot}
        </article>
      </ArticleLayout>
    </>

  );
}

type HeadlineProps = {
  canonicalUrl: string,
  shareUrl: string,
  headlineElement: HeadlineElementType,
  isPhotoBlog: boolean,
  photoBlogChannel: string,
  magazineLayout: MagazineLayoutType,
  magazineArticleViewType: ?string,
  themeColor: ?string,
  showDate: boolean,
};

Headline.defaultProps = {
  canonicalUrl: null,
  shareUrl: null,
  headlineElement: null,
  isPhotoBlog: false,
  photoBlogChannel: 'photoBlog',
  magazineLayout: null,
  magazineArticleViewType: null,
  themeColor: null,
  showDate: true,
};

function Headline({
  canonicalUrl,
  shareUrl,
  headlineElement,
  isPhotoBlog,
  photoBlogChannel,
  magazineLayout,
  magazineArticleViewType,
  themeColor,
  showDate,
}: HeadlineProps) {
  const { css, theme, } = useFela();
  const authors = useAuthorsData();
  const header = useArticleHeaderData();

  return (
    <>
      <MagazineArticleHeader
        {...header}
        isPhotoBlog={isPhotoBlog}
        magazineLayout={magazineLayout}
        themeColor={themeColor}
        showDate={showDate}
        variationB={magazineArticleViewType === 'side'}
        {...(isPhotoBlog
          ? {
            customExclusive:
              theme.magazineExclusivesI18n[photoBlogChannel].name,
            customExclusiveHref:
              theme.magazineExclusivesI18n[photoBlogChannel].href,
          }
          : {})}
        canonicalUrl={canonicalUrl}
        headlineElement={headlineElement}
      />
      {authors ? (
        <div
          className={css({
            maxWidth: magazineLayout.maxWidth.xl,
            paddingInlineStart: magazineLayout.innerPadding.xl,
            paddingInlineEnd: magazineLayout.innerPadding.xl,
            marginRight: 'auto',
            marginLeft: 'auto',
            extend: [
              theme.mq(
                { from: 'l', until: 'xl', },
                {
                  maxWidth: magazineLayout.maxWidth.l,
                  paddingInlineStart: magazineLayout.innerPadding.l.start,
                  paddingInlineEnd: magazineLayout.innerPadding.l.end,
                }
              ),
              theme.mq({ until: 'l', }, { display: 'none', }),
            ],
          })}
        >
          <div
            className={css({
              zIndex: 1,
              paddingTop: '1rem',
              extend: [
                theme.mq(
                  { from: 'xl', },
                  {
                    float: 'start',
                    clear: 'start',
                    marginBottom: '4rem',
                    marginInlineStart: `-${magazineLayout.innerPadding.xl}`,
                    paddingInlineEnd: magazineLayout.spacing.xl,
                    width: magazineLayout.innerPadding.xl,
                  }
                ),
                theme.mq(
                  { from: 'l', until: 'xl', },
                  {
                    float: 'start',
                    clear: 'start',
                    marginBottom: '4rem',
                    marginInlineStart: `-${magazineLayout.innerPadding.l.start}`,
                    paddingInlineEnd: magazineLayout.spacing.l,
                    width: magazineLayout.innerPadding.l.start,
                  }
                ),
              ],
            })}
          >
            <ArticleHeaderMeta
              isPhotoBlog={isPhotoBlog}
              showDate={showDate}
              variationMq={{
                a: { until: 'l', },
                c: { from: 'l', },
              }}
              miscStyles={{ alignItems: 'flex-start', }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

MagazineArticle.defaultProps = {
  isPhotoBlog: false,
  photoBlogChannel: 'photoBlog',
  themeColor: 'defaultTheme',
  showDate: true,
};

export default MagazineArticle;
