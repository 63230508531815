// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
// import type { Node, } from 'react';

import type { StyleProps, } from '@haaretz/htz-css-tools';
import ActionButtons from '../../../ActionButtons/ActionButtons';
import { MobileShareBar, } from '../../../ShareBar/ShareBar';
import useIsLabel from '../../../../hooks/Page/useIsLabel';
import usePrint from '../../../../hooks/Page/usePrint';
import WebViewExclude from '../../../WebViewExclude/WebViewExclude';

type Props = {
  canonicalUrl: string,
  isPhotoBlog: boolean,
  title: string,
  themeColor: ?string,
  miscStyles: ?StyleProps,
};

ShareBar.defaultProps = {
  themeColor: 'defaultTheme',
};

function ShareBar({ isPhotoBlog, canonicalUrl, title, miscStyles, themeColor, }: Props) {
  // const [ isOpen, setIsOpen, ] = useState(false);
  // const [ hover, setHover, ] = useState(false);
  // const toggleOpen: boolean => void = () => setIsOpen(prevOpenState => !prevOpenState);

  // // changeFocus: boolean => void = (focused: boolean) => this.setState({
  // //   focused,
  // // });

  // const toggleHover: boolean => void = (hover: boolean) => setHover(hover);
  const { css, theme, } = useFela();
  const isLabel = useIsLabel();
  const { isPrint, } = usePrint();

  if (isPrint) {
    return null;
  }

  const photoBlogColor = theme.color('bodyText', 'inverse');
  const photoBlogHoverColor = theme.color('quaternary');
  return (
    <>
      <MobileShareBar title={title} canonicalUrl={canonicalUrl} />
      <div
        className={css({
          marginTop: '5rem',
          display: 'flex',
          justifyContent: 'center',
          width: '71rem',
          marginRight: 'auto',
          marginLeft: 'auto',
          transitionProperty: 'width',
          extend: [
            theme.mq({ until: 's', }, { display: 'none', }),
            theme.mq({ from: 'l', until: 'xl', }, { marginTop: '8rem', }),
            theme.getDelay('transition', -1),
            theme.getDuration('transition', -1),
            theme.getTimingFunction('transition', 'linear'),
            ...(miscStyles
              ? parseStyleProps(miscStyles, theme.mq, theme.type)
              : []),
          ],
        })}
        data-test="shareBar"
      >
        <WebViewExclude>
          <ActionButtons
            elementName={title}
            elementUrl={canonicalUrl}
            buttons={theme.shareBarI18n.magazineShareButtons({ theme, isPhotoBlog, themeColor, isLabel, })}
            miscStyles={{ gap: '2rem', }}
            globalButtonsStyles={{
              ...(isPhotoBlog
                ? {
                  color: photoBlogColor,
                  ':hover': {
                    color: photoBlogHoverColor,
                  },
                }
                : {
                  color: theme.color(themeColor, 'shareBarButton'),
                  ':hover': {
                    color: theme.color(themeColor, 'shareBarButtonHover'),
                  },
                }),
              minWidth: '8rem',
            }}
            size={4.5}
          />
        </WebViewExclude>
      </div>
    </>
  );
}

export default ShareBar;
